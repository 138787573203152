export default {
	navTheme: "dark",
	primaryColor: "#1890ff",
	layout: "side",
	contentWidth: "Fluid",
	fixedHeader: false,
	autoHideHeader: false,
	fixSiderbar: false,
	colorWeak: false,
	menu: {
		locale: true,
	},
	title: "LDCS Admin Portal",
	pwa: false,
	iconfontUrl: "",
};
