import "lodash";
import moment from "moment";
import { svc_search, svc_addOne, svc_editOne, svc_deleteOne } from "@/services/";
import logger from "@/utils/logger";
import { showServerResponse } from "@/utils/message";

const endpointBasePrefix = "notifications";

const Model = {
	namespace: "m_Notification",
	state: {
		notifications: [],
	},
	reducers: {
		updateModelState(state, { payload: { response } }) {
			return { ...state, notifications: response };
		},
	},
	effects: {
		*fetchAll({ payload }, { call, put }) {
			const response = yield call(svc_search, endpointBasePrefix, payload);
			if (!showServerResponse(response, true)) return;

			logger("** @effects - fetchAll: " + JSON.stringify(response), 4);
			_.forEach(response.result, function (row) {
				row.key = row.id;

				// remove zulu identifier from iso datetime. all times are in utc +0:00
                row.publish_at_string = row.publish_at.slice(0, -1);
				row.publish_at = moment(row.publish_at_string);
			});

			yield put({
				type: "updateModelState",
				payload: { response },
			});
		},
		*addOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			const datetimeToSave = `${moment(payload.publish_at).format("YYYY-MM-DDTHH:mm")}:00.000Z`;
			payload.publish_at = datetimeToSave;
			const response = yield call(svc_addOne, endpointBasePrefix, payload, payload.id);
			return showServerResponse(response);
		},
		*editOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			// convert publish date to string. ignoring user's local timezone.
			const datetimeToSave = `${moment(payload.publish_at).format("YYYY-MM-DDTHH:mm")}:00.000Z`;
			payload.publish_at = datetimeToSave;
			const response = yield call(svc_editOne, endpointBasePrefix, payload, "id", payload.id);
			return showServerResponse(response);
		},
	},
};

export default Model;
