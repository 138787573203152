"use strict";
import request from "@/utils/request";
import logger from "@/utils/logger";
// Using the base path as a starting point is safer against future folder changes.
const config = require("@/../config/config.json");

export async function svc_uploadImage(form) {
	logger(`@services/policygroup - getPolicy`, 5);
	return request(`${config.apiUrl}images`, {
		method: "POST",
		data: form,
	});
}

export async function svc_downloadFile(endpointBasePrefix, payload) {
	return request(`${config.apiUrl}${endpointBasePrefix}`, {
		method: "POST",
		data: payload,
		responseType: "blob",
	});
}
