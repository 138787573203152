import "lodash";
import { svc_fetchAll } from "@/services";
import logger from "@/utils/logger";
import { convertISODateTimeToString } from "@/utils/convertTime";
import { showServerResponse } from "@/utils/message";
const endpointBasePrefix = "requestlog";
/*****************
 *
 * This file demonstrates the use of the convertTime shared function to map the UTC time to the local browser time
 *
 *****************/

const Model = {
	namespace: "m_RequestLog",
	// We are using response as the attribute name of the state of this model, to interface the results
	state: {
		response: [],
	},
	reducers: {
		updateModelState(state, { payload: { response } }) {
			return { ...state, response };
		},
	},
	effects: {
		*fetchAll({ payload }, { call, put }) {
			// The variable name response will be passed on to payload later
			const response = yield call(svc_fetchAll, endpointBasePrefix);
			// if response was not a success code, exit and don't update anything.
			if (!showServerResponse(response, false)) return;

			logger("** @effects - fetchAll: " + JSON.stringify(response), 4);
			_.forEach(response, function (row) {
				row.key = row.id;
				row = convertISODateTimeToString(row, ["created_at"]);
			});
			yield put({
				type: "updateModelState",
				payload: { response },
			});
		},
	},
};

export default Model;
