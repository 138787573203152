import "lodash";
import moment from "moment";
import { svc_search, svc_addOne, svc_editOne, svc_deleteOne } from "@/services/";
import logger from "@/utils/logger";
import { convertISODateTimeToString } from "@/utils/convertTime";
import { showServerResponse } from "@/utils/message";

const endpointBasePrefix = "contacts";

const Model = {
	namespace: "m_Contacts",
	state: {
		contacts: [],
	},
	reducers: {
		updateModelState(state, { payload: { response } }) {
			return { ...state, contacts: response };
		},
	},
	effects: {
		*fetchAll({ payload }, { call, put }) {
			const response = yield call(svc_search, endpointBasePrefix, payload);
			if (!showServerResponse(response, true)) return;

			logger("** @effects - fetchAll: " + JSON.stringify(response), 4);
			_.forEach(response.result, function (row) {
				row.key = row.id;
			});

			yield put({
				type: "updateModelState",
				payload: { response },
			});
		},
		*addOne({ payload }, { call, select }) {
			payload = _.omit(payload, ["key"]);

			// set contact country code
			const countries = yield select((state) => state.m_Country.countries);
			payload.country_alpha_2_code = countries.find(c => c.en_short_name === payload.country).alpha_2_code;

			const response = yield call(svc_addOne, endpointBasePrefix, payload, payload.id);
			return showServerResponse(response);
		},
		*editOne({ payload }, { call, select }) {
			payload = _.omit(payload, ["key"]);

			// set contact country code
			const countries = yield select((state) => state.m_Country.countries);
			payload.country_alpha_2_code = countries.find(c => c.en_short_name === payload.country).alpha_2_code;

			const response = yield call(svc_editOne, endpointBasePrefix, payload, "id", payload.id);
			return showServerResponse(response);
		},
		*deleteOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			const response = yield call(
				svc_deleteOne,
				endpointBasePrefix,
				payload,
				"id",
				payload.ip,
			);
			return showServerResponse(response);
		},
	},
};

export default Model;
