import { parse } from "querystring";
import { pathToRegexp } from "path-to-regexp";
import moment from "moment-timezone";

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path) => reg.test(path);

export const getPageQuery = () => parse(window.location.href.split("?")[1]);
/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */

export const getAuthorityFromRouter = (router = [], pathname) => {
	const authority = router.find(({ path }) => path && pathToRegexp(path).exec(pathname));
	if (authority) return authority;
	return undefined;
};

export const getRouteAuthority = (path, routeData) => {
	let authorities;
	routeData.forEach((route) => {
		// match prefix
		if (pathToRegexp(`${route.path}/(.*)`).test(`${path}/`)) {
			if (route.authority) {
				authorities = route.authority;
			} // exact match

			if (route.path === path) {
				authorities = route.authority || authorities;
			} // get children authority recursively

			if (route.routes) {
				authorities = getRouteAuthority(path, route.routes) || authorities;
			}
		}
	});
	return authorities;
};

export const parseTime = (time, timeZone = null) => {
	if (timeZone) {
		const match = timeZone.match(/UTC ([+-]\d{1,2}):(\d{2})/);
		if (!match) timeZone = null;
		else {
			const offsetHours = parseInt(match[1], 10);
			const offsetMinutes = parseInt(match[2], 10);
			const offset = offsetHours * 60 + offsetMinutes; // Offset in minutes

			const timezones = moment.tz.names();
			let found = false;
			for (const tz of timezones) {
				const tzOffset = moment.tz(tz).utcOffset();
				if (tzOffset === offset) {
					timeZone = tz;
					found = true;
					break;
				}
			}

			if (!found) timeZone = null;
		}
	}

	time = time.replace("Z", "");
	time = time.replace("T", " ");

	if (!timeZone) timeZone = "Asia/Singapore";

	return moment.tz(time, timeZone);
};
