import "lodash";
import { svc_search } from "@/services/";
import logger from "@/utils/logger";
import { showServerResponse } from "@/utils/message";

const endpointBasePrefix = "devices";

const Model = {
	namespace: "m_Device",
	state: {
		devices: [],
	},
	reducers: {
		updateModelState(state, { payload: { response } }) {
			return { ...state, devices: response };
		},
	},
	effects: {
		*fetchAll({ payload }, { call, put }) {
			const response = yield call(svc_search, endpointBasePrefix, payload);
			if (!showServerResponse(response, true)) return;

			logger("** @effects - fetchAll: " + JSON.stringify(response), 4);
			_.forEach(response.result, function (row) {
				row.key = row.id;
			});

			yield put({
				type: "updateModelState",
				payload: { response },
			});
		},
	},
};

export default Model;
