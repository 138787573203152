export default {
	"component.globalHeader.search": "站内搜索",
	"component.globalHeader.search.example1": "搜索提示一",
	"component.globalHeader.search.example2": "搜索提示二",
	"component.globalHeader.search.example3": "搜索提示三",
	"component.globalHeader.help": "使用文档",
	"component.globalHeader.notification": "通知",
	"component.globalHeader.notification.empty": "你已查看所有通知",
	"component.globalHeader.message": "消息",
	"component.globalHeader.message.empty": "您已读完所有消息",
	"component.globalHeader.event": "待办",
	"component.globalHeader.event.empty": "你已完成所有待办",
	"component.noticeIcon.clear": "清空",
	"component.noticeIcon.cleared": "清空了",
	"component.noticeIcon.empty": "暂无数据",
	"component.noticeIcon.view-more": "查看更多",
};
