"use strict";
import moment from "moment";
import logger from "@/utils/logger";

// Payload can be Array of Object, Object or a string
export function convertISODateTimeToString(
	payload,
	field,
	desiredFormat = "YYYY-MM-DD hh:mm:ss A",
	returnNullIfInvalid = true,
) {
	if (Array.isArray(field)) {
		field.map((key) => {
			if (payload[key]) {
				payload[key] = safelyConvertDateTime(
					payload[key],
					desiredFormat,
					returnNullIfInvalid,
				);
			}
		});
	} else {
		if (field) {
			if (payload[field]) {
				payload[field] = safelyConvertDateTime(
					payload[field],
					desiredFormat,
					returnNullIfInvalid,
				);
			}
		} else {
			payload = safelyConvertDateTime(payload, desiredFormat, returnNullIfInvalid);
		}
	}
	return payload;
}

// Returns null if the string is not valid.
export function convertStringToISODateTime(payload, field) {
	if (Array.isArray(field)) {
		field.map((key) => {
			if (payload[key]) {
				payload[key] = safelyConvertDateTimeToISO(payload[key]);
			}
		});
	} else {
		if (field) {
			if (payload[field]) {
				payload[field] = safelyConvertDateTimeToISO(payload[field]);
			}
		} else {
			payload = safelyConvertDateTimeToISO(payload);
		}
	}
	logger(payload, 5);
	return payload;
}

function safelyConvertDateTime(stringPayload, desiredFormat, returnNullIfInvalid) {
	let output = moment(stringPayload).format(desiredFormat);
	if (output == "Invalid date") {
		// Three possible business scenarios.
		// 1 - we want the original value, maybe null
		// 2 - we want some default value, e.g. 2020-01-01 00:00:00 AM
		// 3 - value supplied was a corrupted value, e.g. "abcde" - in this case, should we return null?
		// Implementation: depending on returnNullIfInvalid, it will either return null or the original value for the caller to handle.
		output = returnNullIfInvalid ? null : stringPayload;
	}

	return output;
}

function safelyConvertDateTimeToISO(stringPayload) {
	moment(stringPayload).isValid() ? stringPayload : null;
	if (stringPayload == null) return null;
	// By this point, we are sure the string is valid.
	return moment(stringPayload).toISOString();
}
