// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/home/user/raritan/app/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelMBanner0 from '/home/user/raritan/app/src/models/m_Banner.js';
import ModelMContacts1 from '/home/user/raritan/app/src/models/m_Contacts.js';
import ModelMCountry2 from '/home/user/raritan/app/src/models/m_Country.js';
import ModelMDevice3 from '/home/user/raritan/app/src/models/m_Device.js';
import ModelMDnsLogs4 from '/home/user/raritan/app/src/models/m_DnsLogs.js';
import ModelMEmailSentLog5 from '/home/user/raritan/app/src/models/m_EmailSentLog.js';
import ModelMEvents6 from '/home/user/raritan/app/src/models/m_Events.js';
import ModelMGlobal7 from '/home/user/raritan/app/src/models/m_Global.js';
import ModelMImages8 from '/home/user/raritan/app/src/models/m_Images.js';
import ModelMIpData9 from '/home/user/raritan/app/src/models/m_IPData.js';
import ModelMNews10 from '/home/user/raritan/app/src/models/m_News.js';
import ModelMNotification11 from '/home/user/raritan/app/src/models/m_Notification.js';
import ModelMPolicyGroup12 from '/home/user/raritan/app/src/models/m_PolicyGroup.js';
import ModelMProducts13 from '/home/user/raritan/app/src/models/m_Products.js';
import ModelMRegistrants14 from '/home/user/raritan/app/src/models/m_Registrants.js';
import ModelMRequestLog15 from '/home/user/raritan/app/src/models/m_RequestLog.js';
import ModelMSetting16 from '/home/user/raritan/app/src/models/m_Setting.js';
import ModelMSms17 from '/home/user/raritan/app/src/models/m_Sms.js';
import ModelMSmsSentLog18 from '/home/user/raritan/app/src/models/m_SmsSentLog.js';
import ModelMTraining19 from '/home/user/raritan/app/src/models/m_Training.js';
import ModelMUpdates20 from '/home/user/raritan/app/src/models/m_Updates.js';
import ModelMUsers21 from '/home/user/raritan/app/src/models/m_Users.js';
import ModelMUserSession22 from '/home/user/raritan/app/src/models/m_UserSession.js';
import ModelMWarranty23 from '/home/user/raritan/app/src/models/m_Warranty.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'm_Banner', ...ModelMBanner0 });
app.model({ namespace: 'm_Contacts', ...ModelMContacts1 });
app.model({ namespace: 'm_Country', ...ModelMCountry2 });
app.model({ namespace: 'm_Device', ...ModelMDevice3 });
app.model({ namespace: 'm_DnsLogs', ...ModelMDnsLogs4 });
app.model({ namespace: 'm_EmailSentLog', ...ModelMEmailSentLog5 });
app.model({ namespace: 'm_Events', ...ModelMEvents6 });
app.model({ namespace: 'm_Global', ...ModelMGlobal7 });
app.model({ namespace: 'm_Images', ...ModelMImages8 });
app.model({ namespace: 'm_IPData', ...ModelMIpData9 });
app.model({ namespace: 'm_News', ...ModelMNews10 });
app.model({ namespace: 'm_Notification', ...ModelMNotification11 });
app.model({ namespace: 'm_PolicyGroup', ...ModelMPolicyGroup12 });
app.model({ namespace: 'm_Products', ...ModelMProducts13 });
app.model({ namespace: 'm_Registrants', ...ModelMRegistrants14 });
app.model({ namespace: 'm_RequestLog', ...ModelMRequestLog15 });
app.model({ namespace: 'm_Setting', ...ModelMSetting16 });
app.model({ namespace: 'm_Sms', ...ModelMSms17 });
app.model({ namespace: 'm_SmsSentLog', ...ModelMSmsSentLog18 });
app.model({ namespace: 'm_Training', ...ModelMTraining19 });
app.model({ namespace: 'm_Updates', ...ModelMUpdates20 });
app.model({ namespace: 'm_Users', ...ModelMUsers21 });
app.model({ namespace: 'm_UserSession', ...ModelMUserSession22 });
app.model({ namespace: 'm_Warranty', ...ModelMWarranty23 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
