export default {
	"user-login.login.userName": "用户名",
	"user-login.login.password": "密码",
	"user-login.login.ssoUserName": "SSO 用户名",
	"user-login.login.ssoPassword": "SSO 密码",
	"user-login.login.message-set-new-password": "请输入新密码！",
	"user-login.login.message-invalid-credentials": "账户或密码错误（admin/ant.design）",
	"user-login.login.message-invalid-verification-code": "验证码错误",
	"user-login.login.tab-login-credentials": "账户密码登录",
	"user-login.login.tab-login-sso": "Single Sign On",
	"user-login.login.tab-login-mobile": "手机号登录",
	"user-login.login.remember-me": "自动登录",
	"user-login.login.forgot-password": "忘记密码",
	"user-login.login.sign-in-with": "其他登录方式",
	"user-login.login.signup": "注册账户",
	"user-login.login.login": "登录",
	"user-login.register.register": "注册",
	"user-login.register.get-verification-code": "获取验证码",
	"user-login.register.sign-in": "使用已有账户登录",
	"user-login.register-result.msg": "你的账户：{email} 注册成功",
	"user-login.register-result.activation-email":
		"激活邮件已发送到你的邮箱中，邮件有效期为24小时。请及时登录邮箱，点击邮件中的链接激活帐户。",
	"user-login.register-result.back-home": "返回首页",
	"user-login.register-result.view-mailbox": "查看邮箱",
	"user-login.email.required": "请输入邮箱地址！",
	"user-login.email.wrong-format": "邮箱地址格式错误！",
	"user-login.userName.required": "请输入用户名!",
	"user-login.password.required": "请输入密码！",
	"user-login.password.twice": "两次输入的密码不匹配!",
	"user-login.strength.msg": "请至少输入 6 个字符。请不要使用容易被猜到的密码。",
	"user-login.strength.strong": "强度：强",
	"user-login.strength.medium": "强度：中",
	"user-login.strength.short": "强度：太短",
	"user-login.confirm-password.required": "请确认密码！",
	"user-login.phone-number.required": "请输入手机号！",
	"user-login.phone-number.wrong-format": "手机号格式错误！",
	"user-login.verification-code.required": "请输入验证码！",
	"user-login.title.required": "请输入标题",
	"user-login.date.required": "请选择起止日期",
	"user-login.goal.required": "请输入目标描述",
	"user-login.standard.required": "请输入衡量标准",
	"user-login.form.get-captcha": "获取验证码",
	"user-login.captcha.second": "秒",
	"user-login.form.optional": "（选填）",
	"user-login.form.submit": "提交",
	"user-login.form.save": "保存",
	"user-login.email.placeholder": "邮箱",
	"user-login.password.placeholder": "至少6位密码，区分大小写",
	"user-login.confirm-password.placeholder": "确认密码",
	"user-login.phone-number.placeholder": "手机号",
	"user-login.verification-code.placeholder": "验证码",
	"user-login.title.label": "标题",
	"user-login.title.placeholder": "给目标起个名字",
	"user-login.date.label": "起止日期",
	"user-login.placeholder.start": "开始日期",
	"user-login.placeholder.end": "结束日期",
	"user-login.goal.label": "目标描述",
	"user-login.goal.placeholder": "请输入你的阶段性工作目标",
	"user-login.standard.label": "衡量标准",
	"user-login.standard.placeholder": "请输入衡量标准",
	"user-login.client.label": "客户",
	"user-login.label.tooltip": "目标的服务对象",
	"user-login.client.placeholder": "请描述你服务的客户，内部客户直接 @姓名／工号",
	"user-login.invites.label": "邀评人",
	"user-login.invites.placeholder": "请直接 @姓名／工号，最多可邀请 5 人",
	"user-login.weight.label": "权重",
	"user-login.weight.placeholder": "请输入",
	"user-login.public.label": "目标公开",
	"user-login.label.help": "客户、邀评人默认被分享",
	"user-login.radio.public": "公开",
	"user-login.radio.partially-public": "部分公开",
	"user-login.radio.private": "不公开",
	"user-login.publicUsers.placeholder": "公开给",
	"user-login.option.A": "同事甲",
	"user-login.option.B": "同事乙",
	"user-login.option.C": "同事丙",
	"user-login.navBar.lang": "语言",
};
