import { sendSMS } from "@/services/sms";
import { showServerResponse } from "@/utils/message";

const Model = {
	namespace: "m_Sms",
	state: { response: "" },
	reducers: {
		handleSendSMSResult(state, { payload: { response } }) {
			return { ...state, response };
		},
	},
	effects: {
		*sendSMS({ payload }, { call, put }) {
			const response = yield call(sendSMS, payload);

			showServerResponse(response);
		},
	},
	subscriptions: {},
};

export default Model;
