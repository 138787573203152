import "lodash";
import { svc_uploadImage } from "@/services/files";
import logger from "@/utils/logger";
//  import { showServerResponse } from "@/utils/message";

const Model = {
	namespace: "m_Images",
	state: {},
	reducers: {},
	effects: {
		*uploadImage({ payload }, { call, put }) {
			logger("@uploadImage - handling file upload via formdata", 4);
			// The variable name response will be passed on to payload later
			let { image, path, filename, imageType, additionalImageProperties } = payload;
			// Path is the folder to upload image to
			// e.g. { path: brands, image: coca-cola.jpg }

			// Create a multipart-form to upload image to REST Endpoint
			const formData = new FormData();
			formData.append("subpath", path);
			formData.append("image", image);
			formData.append("imageType", imageType);
			if (filename) {
				formData.append("filename", filename);
			}
			if (additionalImageProperties) {
				formData.append("additionalImageProperties", JSON.stringify(additionalImageProperties));
			}
			const response = yield call(svc_uploadImage, formData);
			return response;
		},
	},
	subscriptions: {},
};

export default Model;
