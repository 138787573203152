import request from "@/utils/request";
import logger from "@/utils/logger";
const config = require("../../config/config.json");

//20200208 - JT
//Improved error handling to ensure that only strings get processed.
//All other var types won't be resolved properly by the rest service and should be rejected.
//This will greatly help in figuring out what's going on with the other parts of the code
export async function svc_getIpAddress(ipAddress) {
	logger("******** getIpAddress ipAddress " + ipAddress.toString(), 4);
	if (typeof ipAddress === "object" || ipAddress === null)
		return { message: "Enter a valid IP." };
	if (!ipAddress || ipAddress == "{}") return { message: "Please enter an IP address..." };
	// Can even check for three dots (or regex) here to ensure its a valid IP.
	if (typeof ipAddress === "string") return request(`${config.apiUrl}lookup/${ipAddress}`);
	return { message: "ERROR" };
	//debug - if needed, use hard coded response first.
	//return request(`http://192.168.11.132:8081/api/lookup/8.8.8.8`);
}
