export default {
	"menu.config": "Configuration",
	"menu.config.users": "Users",
	"menu.config.policy-groups": "Policy Groups",
	"menu.welcome": "Welcome",
	"menu.qrcode": "QR Code trial",
	"menu.barcode": "Barcode trial",
	"menu.sent-sms": "Sent SMS",
	"menu.dns": "DNS Logs",
	"menu.access-token": "Access Token",
	"menu.home": "Home",
	"menu.admin": "Admin",
	"menu.login": "Login",
	"menu.register": "Register",
	"menu.register.result": "Register Result",
	"menu.dashboard": "Dashboard",
	"menu.exception.403": "403",
	"menu.exception.404": "404",
	"menu.exception.500": "500",
	"menu.form": "Form",
	"menu.page.ip-database": "IP Database",
	"menu.page.ip-database.view": "View",
	"menu.page.ip-database.logs": "Logs",
	"menu.page.request-logs": "Request Logs",
	"menu.sent-email": "Sent Emails",
	"menu.profile": "Profile",
	"menu.result": "Result",
	"menu.result.success": "Success",
	"menu.result.fail": "Fail",
	"menu.exception": "Exception",
	"menu.exception.not-permission": "403",
	"menu.exception.not-find": "404",
	"menu.exception.server-error": "500",
	"menu.exception.trigger": "Trigger",
	"menu.account": "Account",
	"menu.account.password": "Change Password",
	"menu.account.center": "Account Center",
	"menu.account.settings": "Account Settings",
	"menu.account.trigger": "Trigger Error",
	"menu.account.logout": "Logout",
	"menu.editor": "Graphic Editor",
	"menu.editor.flow": "Flow Editor",
	"menu.editor.mind": "Mind Editor",
	"menu.editor.koni": "Koni Editor",
	"menu.change-password": "Change Password",
	"menu.config.access-control": "Access Control",
	"menu.config.application-settings": "Application Settings",
	"menu.news": "News",
	"menu.updates": "Updates",
	"menu.events": "Events",
	"menu.training": "Training",
	"menu.registrants": "Registrants",
	"menu.products": "Products",
	"menu.contacts": "Contacts",
	"menu.banner": "Banner",
	"menu.warranty": "Warranty",
	"menu.devices": "Devices",
	"menu.notifications": "Notifications",
	"menu.user-management": "User Management",
	"menu.user-management.user": "User",
	"menu.user-management.policy-group": "Policy Group",
};
