import request from "@/utils/request";
import "lodash";
import logger from "@/utils/logger";
//Using the base path as a starting point is safer against future folder changes.
const config = require("@/../config/config.json");

export async function getPolicy() {
	logger(`@services/policygroup - getPolicy`, 5);
	return request(`${config.apiUrl}policy/all`, {
		method: "GET",
	});
}

export async function getPolicyGroups() {
	logger(`@services/policygroup - getPolicyGroups`, 5);
	return request(`${config.apiUrl}policy`, {
		method: "GET",
	});
}

export async function addPolicyGroup(params) {
	logger("@services/policygroup.js - Adding new policy group - calling REST endpoint", 3);
	//For now, we need to prune off unwanted fields...
	params = _.omit(params, ["key", "editable", "isNew"]);
	return request(`${config.apiUrl}policy`, {
		method: "POST",
		data: params,
	});
}

export async function editPolicyGroup(params) {
	logger("@services/policygroup.js - In edit submit form...", 3);
	params = _.omit(params, ["key", "editable", "isNew", "created_at", "updated_at"]);
	logger(`@services/policygroup - After omit ${JSON.stringify(params)}`, 5);
	return request(`${config.apiUrl}policy/${params.id}`, {
		method: "PUT",
		data: params,
	});
}

export async function deletePolicyGroup(params) {
	logger("@services/policygroup.js - Deleting Policy Group", 3);
	return request(`${config.apiUrl}policy/${params.id}`, {
		method: "DELETE",
	});
}
