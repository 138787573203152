// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/user/raritan/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/user",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/home/user/raritan/app/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "login",
        "path": "/user/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Session__Login' */'/home/user/raritan/app/src/pages/Session/Login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "challenge",
        "path": "/user/challenge",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Session__Challenge' */'/home/user/raritan/app/src/pages/Session/Challenge'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "twofa-setup",
        "path": "/user/twofa-setup",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Session__TwoFA__Setup' */'/home/user/raritan/app/src/pages/Session/TwoFA/Setup'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/home/user/raritan/app/src/layouts/SecurityLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/home/user/raritan/app/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "redirect": "/dashboard",
            "exact": true
          },
          {
            "name": "dashboard",
            "path": "/dashboard",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/home/user/raritan/app/src/pages/Dashboard'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "news",
            "icon": "PicLeft",
            "path": "/news",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BasicPage' */'/home/user/raritan/app/src/pages/BasicPage'), loading: LoadingComponent}),
            "authority": [
              "sidebar:news"
            ],
            "exact": true
          },
          {
            "name": "updates",
            "icon": "Desktop",
            "path": "/updates",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BasicPage' */'/home/user/raritan/app/src/pages/BasicPage'), loading: LoadingComponent}),
            "authority": [
              "sidebar:updates"
            ],
            "exact": true
          },
          {
            "name": "events",
            "icon": "Global",
            "path": "/events",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BasicPage' */'/home/user/raritan/app/src/pages/BasicPage'), loading: LoadingComponent}),
            "authority": [
              "sidebar:events"
            ],
            "exact": true
          },
          {
            "name": "training",
            "icon": "Read",
            "path": "/training",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BasicPage' */'/home/user/raritan/app/src/pages/BasicPage'), loading: LoadingComponent}),
            "authority": [
              "sidebar:training"
            ],
            "exact": true
          },
          {
            "name": "registrants",
            "icon": "User",
            "path": "/registrants",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Registrants' */'/home/user/raritan/app/src/pages/Registrants'), loading: LoadingComponent}),
            "authority": [
              "sidebar:registrants"
            ],
            "exact": true
          },
          {
            "name": "products",
            "icon": "gift",
            "path": "/products",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BasicPage' */'/home/user/raritan/app/src/pages/BasicPage'), loading: LoadingComponent}),
            "authority": [
              "sidebar:products"
            ],
            "exact": true
          },
          {
            "name": "banner",
            "icon": "PicCenter",
            "path": "/banner",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BasicPage' */'/home/user/raritan/app/src/pages/BasicPage'), loading: LoadingComponent}),
            "authority": [
              "sidebar:banner"
            ],
            "exact": true
          },
          {
            "name": "contacts",
            "icon": "contacts",
            "path": "/contacts",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BasicPage' */'/home/user/raritan/app/src/pages/BasicPage'), loading: LoadingComponent}),
            "authority": [
              "sidebar:contacts"
            ],
            "exact": true
          },
          {
            "name": "warranty",
            "icon": "audit",
            "path": "/warranty",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Warranty' */'/home/user/raritan/app/src/pages/Warranty'), loading: LoadingComponent}),
            "authority": [
              "sidebar:warranty"
            ],
            "exact": true
          },
          {
            "name": "devices",
            "icon": "phone",
            "path": "/devices",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BasicPage' */'/home/user/raritan/app/src/pages/BasicPage'), loading: LoadingComponent}),
            "authority": [
              "sidebar:devices"
            ],
            "exact": true
          },
          {
            "name": "notifications",
            "icon": "bell",
            "path": "/notifications",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BasicPage' */'/home/user/raritan/app/src/pages/BasicPage'), loading: LoadingComponent}),
            "authority": [
              "sidebar:notifications"
            ],
            "exact": true
          },
          {
            "name": "user-management",
            "icon": "user",
            "path": "/user-management",
            "authority": [
              "user:create"
            ],
            "routes": [
              {
                "name": "user",
                "path": "/user-management/user",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManagement__user' */'/home/user/raritan/app/src/pages/UserManagement/user'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "policy-group",
                "path": "/user-management/policy-group",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManagement__policygroup' */'/home/user/raritan/app/src/pages/UserManagement/policygroup'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "change-password",
            "icon": "Unlock",
            "path": "/account/changepassword",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Session__ChangePassword' */'/home/user/raritan/app/src/pages/Session/ChangePassword'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/user/raritan/app/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/user/raritan/app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/user/raritan/app/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
