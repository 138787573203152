import "lodash";
import { svc_fetchAll, svc_addOne, svc_editOne, svc_deleteOne } from "@/services/";
import { svc_getIpAddress } from "@/services/ipdata";
import logger from "@/utils/logger";
import { convertISODateTimeToString } from "@/utils/convertTime";
import { showServerResponse } from "@/utils/message";
const endpointBasePrefix = "ipdata";

/*****************
 *
 * This file demonstrates the use of the convertTime shared function to map the UTC time to the local browser time
 *
 *****************/

const Model = {
	namespace: "m_IPData",
	// We are using response as the attribute name of the state of this model, to interface the results
	state: {
		response: [],
		lookupResult: null,
	},
	reducers: {
		updateModelState(state, { payload: { response } }) {
			return { ...state, response };
		},
		handleLookupResult(state, { payload: { response } }) {
			return { ...state, lookupResult: response };
		},
	},
	effects: {
		*fetchAll({ payload }, { call, put }) {
			// The variable name response will be passed on to payload later
			const response = yield call(svc_fetchAll, endpointBasePrefix);
			// if response was not a success code, exit and don't update anything.
			if (!showServerResponse(response, false)) return;

			logger("** @effects - fetchAll: " + JSON.stringify(response), 4);
			_.forEach(response, function (row) {
				row.key = row.id;
				row = convertISODateTimeToString(row, ["created_at"]);
			});
			yield put({
				type: "updateModelState",
				payload: { response },
			});
		},
		*addOne({ payload }, { call, put }) {
			// Clean up the data before calling service function
			// MUST remove 'key' attribute to prevent API throwing errors about unwanted field.
			// To consider moving to shared function, to have a general sanitization function of such system fields.
			payload = _.omit(payload, ["key"]);
			const response = yield call(svc_addOne, endpointBasePrefix, payload, payload.ip);
			return showServerResponse(response);
		},
		*editOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			const response = yield call(svc_editOne, endpointBasePrefix, payload, "id", payload.ip);
			return showServerResponse(response);
		},
		*deleteOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			const response = yield call(
				svc_deleteOne,
				endpointBasePrefix,
				payload,
				"id",
				payload.ip,
			);
			return showServerResponse(response);
		},
		*lookup({ payload }, { call, put }) {
			const response = yield call(svc_getIpAddress, payload);

			yield put({
				type: "handleLookupResult",
				payload: { response },
			});
		},
	},
	subscriptions: {
		setup({ dispatch, history }) {
			return history.listen(({ pathname, query }) => {
				if (pathname === "/ipdatabase") {
					dispatch({ type: "fetch", payload: query });
				}
			});
		},
	},
};

export default Model;
