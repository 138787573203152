import "lodash";
import { svc_fetchAll } from "@/services/";
import logger from "@/utils/logger";

const endpointBasePrefix = "countries";

const Model = {
	namespace: "m_Country",
	state: {
		countries: [],
	},
	reducers: {
		updateModelState(state, { payload: { response } }) {
			return { ...state, countries: response };
		},
	},
	effects: {
		*fetchSelectXref({ payload }, { call, put, select }) {
			const countries = yield select((state) => state.m_Country.countries);
			if (countries.length > 0) return countries;
			
			const response = yield call(svc_fetchAll, endpointBasePrefix);

			logger("** @effects - fetchAll: " + JSON.stringify(response), 4);
			_.forEach(response.result, function (row) {
				row.key = row.id;
			});

			yield put({
				type: "updateModelState",
				payload: { response },
			});

			return response;
		},
	},
};

export default Model;
