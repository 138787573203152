// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import PicLeftOutlined from '@ant-design/icons/PicLeftOutlined';
import DesktopOutlined from '@ant-design/icons/DesktopOutlined';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import ReadOutlined from '@ant-design/icons/ReadOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import GiftOutlined from '@ant-design/icons/GiftOutlined';
import PicCenterOutlined from '@ant-design/icons/PicCenterOutlined';
import ContactsOutlined from '@ant-design/icons/ContactsOutlined';
import AuditOutlined from '@ant-design/icons/AuditOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import BellOutlined from '@ant-design/icons/BellOutlined';
import UnlockOutlined from '@ant-design/icons/UnlockOutlined'

export default {
  SmileOutlined,
PicLeftOutlined,
DesktopOutlined,
GlobalOutlined,
ReadOutlined,
UserOutlined,
GiftOutlined,
PicCenterOutlined,
ContactsOutlined,
AuditOutlined,
PhoneOutlined,
BellOutlined,
UnlockOutlined
}
    