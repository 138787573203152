"use strict";
import request from "@/utils/request";
import "lodash";
import logger from "@/utils/logger";
// Using the base path as a starting point is safer against future folder changes.
const config = require("@/../config/config.json");

export async function getUsers() {
	logger(`@services/userstable.js - getUsers`, 5);
	return request(`${config.apiUrl}users`);
}

export async function addUser(params) {
	//For now, we need to prune off unwanted fields...
	params = _.omit(params, ["key", "editable", "isNew"]);
	return request(`${config.apiUrl}users`, {
		method: "POST",
		data: params,
	});
}

export async function editUser(params) {
	params = _.omit(params, ["key", "editable", "isNew", "created_at", "updated_at"]);
	logger(`@services/userstable - After omit ${JSON.stringify(params)}`, 5);
	return request(`${config.apiUrl}users/${params.id}`, {
		method: "PUT",
		data: params,
	});
}

export async function deleteUser(params) {
	return request(`${config.apiUrl}users/${params.id}`, {
		method: "DELETE",
	});
}
