import request from "@/utils/request";
import { getAuthorityFromRouter } from "@/utils/utils";
const config = require("../../config/config.json");

export async function sendSMS({ smsNumber, smsText }) {
	return request(`${config.apiUrl}sms/send`, {
		method: "POST",
		data: {
			sms_recipient_country_prefix: "65", //Since it is for testing, we lock the country to SG only.
			sms_recipient_number: smsNumber,
			sms_body_text: smsText,
			// sender_text: "IPLookUp", //optional
		},
	});
}
