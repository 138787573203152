export default {
	"app.settings.menuMap.basic": "基本设置",
	"app.settings.menuMap.security": "安全设置",
	"app.settings.menuMap.binding": "账号绑定",
	"app.settings.menuMap.notification": "新消息通知",
	"app.settings.basic.avatar": "头像",
	"app.settings.basic.change-avatar": "更换头像",
	"app.settings.basic.email": "邮箱",
	"app.settings.basic.email-message": "请输入您的邮箱!",
	"app.settings.basic.nickname": "昵称",
	"app.settings.basic.nickname-message": "请输入您的昵称!",
	"app.settings.basic.profile": "个人简介",
	"app.settings.basic.profile-message": "请输入个人简介!",
	"app.settings.basic.profile-placeholder": "个人简介",
	"app.settings.basic.country": "国家/地区",
	"app.settings.basic.country-message": "请输入您的国家或地区!",
	"app.settings.basic.geographic": "所在省市",
	"app.settings.basic.geographic-message": "请输入您的所在省市!",
	"app.settings.basic.address": "街道地址",
	"app.settings.basic.address-message": "请输入您的街道地址!",
	"app.settings.basic.phone": "联系电话",
	"app.settings.basic.phone-message": "请输入您的联系电话!",
	"app.settings.basic.update": "更新基本信息",
	"app.settings.security.strong": "强",
	"app.settings.security.medium": "中",
	"app.settings.security.weak": "弱",
	"app.settings.security.password": "账户密码",
	"app.settings.security.password-description": "当前密码强度",
	"app.settings.security.phone": "密保手机",
	"app.settings.security.phone-description": "已绑定手机",
	"app.settings.security.question": "密保问题",
	"app.settings.security.question-description": "未设置密保问题，密保问题可有效保护账户安全",
	"app.settings.security.email": "备用邮箱",
	"app.settings.security.email-description": "已绑定邮箱",
	"app.settings.security.mfa": "MFA 设备",
	"app.settings.security.mfa-description": "未绑定 MFA 设备，绑定后，可以进行二次确认",
	"app.settings.security.modify": "修改",
	"app.settings.security.set": "设置",
	"app.settings.security.bind": "绑定",
	"app.settings.binding.taobao": "绑定淘宝",
	"app.settings.binding.taobao-description": "当前未绑定淘宝账号",
	"app.settings.binding.alipay": "绑定支付宝",
	"app.settings.binding.alipay-description": "当前未绑定支付宝账号",
	"app.settings.binding.dingding": "绑定钉钉",
	"app.settings.binding.dingding-description": "当前未绑定钉钉账号",
	"app.settings.binding.bind": "绑定",
	"app.settings.notification.password": "账户密码",
	"app.settings.notification.password-description": "其他用户的消息将以站内信的形式通知",
	"app.settings.notification.messages": "系统消息",
	"app.settings.notification.messages-description": "系统消息将以站内信的形式通知",
	"app.settings.notification.todo": "待办任务",
	"app.settings.notification.todo-description": "待办任务将以站内信的形式通知",
	"app.settings.open": "开",
	"app.settings.close": "关",
};
