// @ts-nocheck
import { IRoute } from '@umijs/core';
import { AnyAction } from 'redux';
import React from 'react';
import { EffectsCommandMap, SubscriptionAPI } from 'dva';
import { match } from 'react-router-dom';
import { Location, LocationState, History } from 'history';

export * from '/home/user/raritan/app/src/models/m_Banner';
export * from '/home/user/raritan/app/src/models/m_Contacts';
export * from '/home/user/raritan/app/src/models/m_Country';
export * from '/home/user/raritan/app/src/models/m_Device';
export * from '/home/user/raritan/app/src/models/m_DnsLogs';
export * from '/home/user/raritan/app/src/models/m_EmailSentLog';
export * from '/home/user/raritan/app/src/models/m_Events';
export * from '/home/user/raritan/app/src/models/m_Global';
export * from '/home/user/raritan/app/src/models/m_Images';
export * from '/home/user/raritan/app/src/models/m_IPData';
export * from '/home/user/raritan/app/src/models/m_News';
export * from '/home/user/raritan/app/src/models/m_Notification';
export * from '/home/user/raritan/app/src/models/m_PolicyGroup';
export * from '/home/user/raritan/app/src/models/m_Products';
export * from '/home/user/raritan/app/src/models/m_Registrants';
export * from '/home/user/raritan/app/src/models/m_RequestLog';
export * from '/home/user/raritan/app/src/models/m_Setting';
export * from '/home/user/raritan/app/src/models/m_Sms';
export * from '/home/user/raritan/app/src/models/m_SmsSentLog';
export * from '/home/user/raritan/app/src/models/m_Training';
export * from '/home/user/raritan/app/src/models/m_Updates';
export * from '/home/user/raritan/app/src/models/m_Users';
export * from '/home/user/raritan/app/src/models/m_UserSession';
export * from '/home/user/raritan/app/src/models/m_Warranty';

export interface Action<T = any> {
  type: T
}

export type Reducer<S = any, A extends Action = AnyAction> = (
  state: S,
  action: A
) => S;

export type ImmerReducer<S = any, A extends Action = AnyAction> = (
  state: S,
  action: A
) => void;

export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap,
) => void;

/**
 * @type P: Type of payload
 * @type C: Type of callback
 */
export type Dispatch<P = any, C = (payload: P) => void> = (action: {
  type: string;
  payload?: P;
  callback?: C;
  [key: string]: any;
}) => any;

export type Subscription = (api: SubscriptionAPI, done: Function) => void | Function;

export interface Loading {
  global: boolean;
  effects: { [key: string]: boolean | undefined };
  models: {
    [key: string]: any;
  };
}

/**
 * @type P: Params matched in dynamic routing
 */
export interface ConnectProps<
  P extends { [K in keyof P]?: string } = {},
  S = LocationState,
  T = {}
> {
  dispatch?: Dispatch;
  // https://github.com/umijs/umi/pull/2194
  match?: match<P>;
  location: Location<S> & { query: T };
  history: History;
  route: IRoute;
}

export type RequiredConnectProps<
  P extends { [K in keyof P]?: string } = {},
  S = LocationState,
  T = {}
  > = Required<ConnectProps<P, S, T>>

/**
 * @type T: React props
 * @type U: match props types
 */
export type ConnectRC<
  T = {},
  U = {},
  S = {},
  Q = {}
> = React.ForwardRefRenderFunction<any, T & RequiredConnectProps<U, S, Q>>;

