import "lodash";
import moment from "moment";
import { svc_search, svc_addOne, svc_editOne, svc_deleteOne } from "@/services/";
import logger from "@/utils/logger";
import { convertISODateTimeToString } from "@/utils/convertTime";
import { showServerResponse } from "@/utils/message";

const endpointBasePrefix = "warranty";

const Model = {
	namespace: "m_Warranty",
	state: {
		warranty: [],
	},
	reducers: {
		updateModelState(state, { payload: { response } }) {
			return { ...state, warranty: response };
		},
	},
	effects: {
		*fetchAll({ payload }, { call, put }) {
			const response = yield call(svc_search, endpointBasePrefix, payload);
			if (!showServerResponse(response, true)) return;

			logger("** @effects - fetchAll: " + JSON.stringify(response), 4);
			_.forEach(response.result, function (row) {
				row.key = row.id;
				// display serial numbers. cut off if too long
				row.serial_no_display = row.serial_no ? row.serial_no.map(i => i.serial_no?.serial_no).join(",") : "";
				if (row.serial_no_display.length > 100) row.serial_no_display = row.serial_no_display.slice(0, 100) + "...";
			});

			yield put({
				type: "updateModelState",
				payload: { response },
			});
		},
		*addOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			const response = yield call(svc_addOne, endpointBasePrefix, payload);
			return showServerResponse(response);
		},
		*editOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			const response = yield call(svc_editOne, endpointBasePrefix, payload, "id");
			return showServerResponse(response);
		},
		*deleteOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			const response = yield call(
				svc_deleteOne,
				endpointBasePrefix,
				payload,
				"id",
				payload.ip,
			);
			return showServerResponse(response);
		},
	},
};

export default Model;
