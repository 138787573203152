import "lodash";
import { svc_fetchAll, svc_addOne, svc_editOne, svc_deleteOne, svc_search } from "@/services/";
import logger from "@/utils/logger";
import { convertISODateTimeToString } from "@/utils/convertTime";
import { showServerResponse } from "@/utils/message";
const endpointBasePrefix = "dnslogs";

/*****************
 *
 * This file demonstrates the use of the convertTime shared function to map the UTC time to the local browser time
 *
 *****************/

const Model = {
	namespace: "m_DnsLogs",
	// We are using response as the attribute name of the state of this model, to interface the results
	state: {
		response: [],
		lookupResult: null,
	},
	reducers: {
		updateModelState(state, { payload: { response } }) {
			return { ...state, response };
		},
		handleLookupResult(state, { payload: { response } }) {
			return { ...state, lookupResult: response };
		},
	},
	effects: {
		*fetchAll({ payload }, { call, put }) {
			// The variable name response will be passed on to payload later
			const response = yield call(svc_fetchAll, endpointBasePrefix);
			// if response was not a success code, exit and don't update anything.
			if (!showServerResponse(response, false)) return;

			logger("** @effects - fetchAll: " + JSON.stringify(response), 4);
			_.forEach(response, function (row) {
				row.key = row.id;
				row = convertISODateTimeToString(row, ["created_at"]);
			});
			yield put({
				type: "updateModelState",
				payload: { response },
			});
		
		},

		*search({ payload }, { call, put }) {
			const response = yield call(svc_search, endpointBasePrefix,payload);
			if (!showServerResponse(response, false)) return;
			_.forEach(response, function (row) {
				row.key = row.id;
				row = convertISODateTimeToString(row, ["created_at"]);
			});
			yield put({
				type: "updateModelState",
				payload: { response },
			});
		},
	},
};

export default Model;
 