export default {
	"menu.welcome": "欢迎",
	"menu.config": "Configuration",
	"menu.config.users": "Users",
	"menu.config.policy-groups": "Policy Groups",
	"menu.welcome": "Welcome",
	"menu.qrcode": "QR Code trial",
	"menu.barcode": "Barcode trial",
	"menu.sent-sms": "Sent SMS",
	"menu.dns": "DNS Logs",
	"menu.access-token": "Access Token",
	"menu.more-blocks": "更多区块",
	"menu.home": "首页",
	"menu.admin": "管理页",
	"menu.login": "登录",
	"menu.register": "注册",
	"menu.register.result": "注册结果",
	"menu.dashboard": "Dashboard",
	"menu.dashboard.analysis": "分析页",
	"menu.dashboard.monitor": "监控页",
	"menu.dashboard.workplace": "工作台",
	"menu.exception.403": "403",
	"menu.exception.404": "404",
	"menu.exception.500": "500",
	"menu.page.ip-database": "IP 数据库",
	"menu.page.ip-database.view": "View",
	"menu.page.ip-database.logs": "Logs",
	"menu.page.request-logs": "请求日志",
	"menu.profile": "详情页",
	"menu.profile.basic": "基础详情页",
	"menu.profile.advanced": "高级详情页",
	"menu.result": "结果页",
	"menu.result.success": "成功页",
	"menu.result.fail": "失败页",
	"menu.exception": "异常页",
	"menu.exception.not-permission": "403",
	"menu.exception.not-find": "404",
	"menu.exception.server-error": "500",
	"menu.exception.trigger": "触发错误",
	"menu.account": "个人页",
	"menu.account.center": "个人中心",
	"menu.account.settings": "个人设置",
	"menu.account.trigger": "触发报错",
	"menu.account.logout": "退出登录",
	"menu.editor": "图形编辑器",
	"menu.editor.flow": "流程编辑器",
	"menu.editor.mind": "脑图编辑器",
	"menu.editor.koni": "拓扑编辑器",
};
