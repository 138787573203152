"use strict";
import { notification } from "antd";

export function showServerResponse(response, silentOnSuccess = false) {
	notification.config({
		maxCount: 2,
		duration: 3,
	});

	let responseIsSuccessful = false;

	if (!response) return false;
	if (response.status == null) {
		// Typical Fetch Operations return data directly
		// There will be no "status" key inside the response
		// Thus we will assume that the response is successful
		responseIsSuccessful = true;
	} else {
		if (typeof response.status === "number") {
			// response.status is a number
			// Should be HTTP Status Code
			if (response.status == 200) responseIsSuccessful = true;

			return responseIsSuccessful;
		}

		// Assume response.status is a string
		// Should be response from API
		if (response.status.toUpperCase() == "ERROR") {
			notification.error({
				message: "Error",
				description: response.message,
			});
		} else if (response.status.toUpperCase() === "WARNING") {
			responseIsSuccessful = true;
			if (!silentOnSuccess) {
				notification.warning({
					message: "Warning",
					description: response.message,
				});
			}
		} else if (response.status.toUpperCase() == "OK") {
			responseIsSuccessful = true;
			if (!silentOnSuccess) {
				notification.success({
					message: "Success",
					description: response.message,
				});
			}
		}
	}

	return responseIsSuccessful;
}
