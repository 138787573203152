import "lodash";
import moment from "moment";
import { svc_search, svc_addOne, svc_editOne, svc_deleteOne } from "@/services/";
import logger from "@/utils/logger";
import { showServerResponse } from "@/utils/message";
import findLexicalText from "@/utils/findLexicalText";

const endpointBasePrefix = "eventstraining";

const Model = {
	namespace: "m_Events",
	state: {
		events: [],
	},
	reducers: {
		updateModelState(state, { payload: { response } }) {
			return { ...state, events: response };
		},
	},
	effects: {
		*fetchAll({ payload }, { call, put }) {
			payload.filter = { type: "Event" };
			const response = yield call(svc_search, endpointBasePrefix, payload);
			response.message = response.status === "OK" ? "Events successfully fetched" : response.message;
			if (!showServerResponse(response, true)) return;

			logger("** @effects - fetchAll: " + JSON.stringify(response), 4);
			_.forEach(response.result, function (row) {
				row.key = row.id;
				// need to find all text keys
				if (row.description) {
					try {
						const lexicalObj = JSON.parse(row.description);
						row.description_short = findLexicalText(lexicalObj, "text").join(" ").substring(0, 200) + "...";
					} catch (e) {
						// description is not a json string
						row.description_short = row.description.substring(0, 200) + "...";
					}
				}
				// enable switch if source_url is present
				if (row.source_url)
					row.is_url = true;


				// remove zulu identifier from iso datetime. all times are in utc +0:00
				row.start_date_string = row.start_date.slice(0, -1);
				row.start_date = moment(row.start_date_string);
				row.end_date_string = row.end_date.slice(0, -1);
				row.end_date = moment(row.end_date_string);
				row.created_at = moment(row.created_at);

				// hide Registrants button if enable_registration is false
				row.registrantsHidden = !row.enable_registration;

				// hide Notifications button if event is deleted
				row.notificationHidden = row.is_deleted;
			});

			yield put({
				type: "updateModelState",
				payload: { response },
			});
		},
		*addOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key", "is_url"]);
			const startDatetimeToSave = `${moment(payload.start_date).format("YYYY-MM-DDTHH:mm")}:00.000Z`;
			const endDatetimeToSave = `${moment(payload.end_date).format("YYYY-MM-DDTHH:mm")}:00.000Z`;
			payload.start_date = startDatetimeToSave;
			payload.end_date = endDatetimeToSave;
			payload.type = "Event";
			const response = yield call(svc_addOne, endpointBasePrefix, payload, payload.id);
			return showServerResponse(response);
		},
		*editOne({ payload }, { call, put }) {
			if (!payload.is_url)
				payload.source_url = null;
			payload = _.omit(payload, ["key", "is_url"]);
			const startDatetimeToSave = `${moment(payload.start_date).format("YYYY-MM-DDTHH:mm")}:00.000Z`;
			const endDatetimeToSave = `${moment(payload.end_date).format("YYYY-MM-DDTHH:mm")}:00.000Z`;
			payload.start_date = startDatetimeToSave;
			payload.end_date = endDatetimeToSave;
			const response = yield call(svc_editOne, endpointBasePrefix, payload, "id", payload.id);
			return showServerResponse(response);
		},
		*deleteOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key", "is_url"]);
			const response = yield call(
				svc_deleteOne,
				endpointBasePrefix,
				payload,
				"id",
				payload.ip,
			);
			return showServerResponse(response);
		},
	},
};

export default Model;
