"use strict";
/*************************************************
 * Description: This file is used to retrieve JWT Token from localstorage
 *
 * The localstorage name for storing JWT Token can be change by updating settings.json.
 * This is to facilitate SSO across different geek apps
 *
 * Note: JWT Reading from multiple apps can only be done if they are under the same domain
 *************************************************/
import { jwt } from "../../config/config.json";
export const getJWTToken = () => {
	let jwtToken = null;
	if (jwt && jwt.keyName) {
		jwtToken = localStorage.getItem(jwt.keyName);
	} else {
		jwtToken = localStorage.getItem("geek-app-jwt-token");
	}

	if (jwtToken && jwtToken != "null") {
		return jwtToken;
	} else {
		return null;
	}
};

export const setJWTToken = (response) => {
	let jwtToken = null;
	if (jwt && jwt.attributeName) {
		jwtToken = response[jwt.attributeName];
	} else {
		jwtToken = response.token;
	}

	if (!jwtToken) {
		// could be cookie.
		return;
	}

	if (jwt.keyName) {
		localStorage.setItem(jwt.keyName, jwtToken);
	} else {
		localStorage.setItem("geek-app-jwt-token", jwtToken);
	}
};
