import logger from "@/utils/logger";
import { history } from "umi";

export function redirectUserToLoginPage(redirect = "") {
	logger("Redirecting user to login page.", 0);
	if (window.location.pathname !== "/user/login" && !redirect) {
		history.push({
			pathname: "/user/login",
			query: {
				redirect: window.location.href,
			},
		});
	}
}

export function clearStorageAndRedirectToLogin() {
	logger("Redirecting user to login page.", 0);
	localStorage.clear();
	if (window.location.pathname !== "/user/login") {
		history.push({
			pathname: "/user/login",
			query: {
				redirect: "/",
			},
		});
	}
}
