import { getPolicy, getPolicyGroups, addPolicyGroup, editPolicyGroup, deletePolicyGroup } from "@/services/policygroup";
import "lodash";
import logger from "@/utils/logger";
import { showServerResponse } from "@/utils/message.js";

const Model = {
	namespace: "policygroup",
	state: {
		policygroups: [],
		policy: [],
	},
	reducers: {
		handleFetchAll(state, { payload: { policygroups, policy } }) {
			logger(`** PolicyGroup reducers response ${JSON.stringify(policygroups)}`, 5);
			return { ...state, policygroups, policy };
		},
	},
	effects: {
		*fetchAll({ searchKey }, { call, put }) {
			let policygroups = yield call(getPolicyGroups);
			_.forEach(policygroups, function (row) {
				row.key = row.id;
			});
			let policy = yield call(getPolicy);
			yield put({
				type: "handleFetchAll",
				payload: { policygroups, policy },
			});
		},
		*addPolicyGroup({ payload }, { call, put }) {
			let response = yield call(addPolicyGroup, payload);
			return showServerResponse(response);
		},
		*editPolicyGroup({ payload }, { call, put }) {
			let response = yield call(editPolicyGroup, payload);
			return showServerResponse(response);
		},
		*deletePolicyGroup({ payload }, { call, put }) {
			let response = yield call(deletePolicyGroup, payload);
			return showServerResponse(response);
		},
	},
};

export default Model;
