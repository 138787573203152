"use strict";
import { getUsers, addUser, editUser, deleteUser } from "@/services/userstable";
import { svc_fetchAll, svc_addOne, svc_editOne, svc_deleteOne } from "@/services";
import { message } from "antd";
import "lodash";
import logger from "@/utils/logger";
import { showServerResponse } from "@/utils/message.js";
const endpointBasePrefix = "users";

const Model = {
	namespace: "userstable",
	state: {},
	reducers: {
		handleGetUsers(state, { payload: { tableData } }) {
			logger(`@userstable.js: reducers response ${JSON.stringify(tableData)}`, 5);
			return { ...state, tableData };
		},
		saveCurrentUser(state, action) {
			return { ...state, currentUser: action.payload || {} };
		},
	},
	effects: {
		*fetchAll({ payload }, { call, put }) {
			let tableData = yield call(svc_fetchAll, endpointBasePrefix);
			_.forEach(tableData, function (row) {
				row.key = row.id;
			});

			yield put({
				type: "handleGetUsers",
				payload: { tableData },
			});
		},
		*addOne({ payload }, { call, put }) {
			let response = yield call(
				svc_addOne,
				endpointBasePrefix,
				payload,
				payload.username,
			);
			return showServerResponse(response);
		},
		*editOne({ payload }, { call }) {
			let response = yield call(editUser, payload);
			return showServerResponse(response);
		},
		*deleteOne({ payload }, { call, put }) {
			let response = yield call(deleteUser, payload);
			return showServerResponse(response);
		},
	},
	// subscriptions are not necessary in this model.
	// subscriptions: {
	// 	setup({ dispatch, history }) {
	// 		return history.listen(({ pathname, query }) => {
	// 			if (pathname === "/userstable") {
	// 				dispatch({ type: "fetch", payload: query });
	// 			}
	// 		});
	// 	},
	// },
};

export default Model;
