import { userLogin, userLogout, getMe, changePassword, setupUserTwoFA } from "@/services/session";
import { svc_fetchAll, svc_addOne, svc_editOne, svc_deleteOne } from "@/services";
import config from "../../config/config.json";
import { getPageQuery } from "@/utils/utils";
import { history } from "umi";
import logger from "@/utils/logger";
import { redirectUserToLoginPage, clearStorageAndRedirectToLogin } from "@/utils/redirect";
import { showServerResponse } from "@/utils/message.js";
import { getJWTToken, setJWTToken } from "@/utils/jwt";
import moment from "moment";

const UserModel = {
	namespace: "m_UserSession",
	state: {
		currentUser: {},
		fetched_user_info: false,
		status: undefined,
		access_tokens: [],
		token_stats: {
			valid: 0,
			revoke: 0,
		},
		twofa_setup: null,
		challenge_details: null,
	},
	reducers: {
		updateCurrentUserState(state, { payload }) {
			let api_user_information = JSON.stringify(payload);
			let locally_stored_user_information = localStorage.getItem("user-information");
			if (api_user_information !== locally_stored_user_information) {
				localStorage.setItem("user-information", api_user_information);
				location.reload();
			}
			if (payload.id) {
				return { ...state, currentUser: payload, fetched_user_info: true };
			} else {
				return { ...state, fetched_user_info: false };
			}
		},
		changeLoginStatus(state, { payload }) {
			return { ...state, status: payload.status, type: payload.type };
		},
		updateAccessTokens(state, { payload }) {
			let stats = {
				valid: 0,
				revoke: 0,
			};
			for (let i = 0; i < payload.length; i++) {
				const { status } = payload[i];
				if (status.toUpperCase() === "VALID") stats.valid++;
				else if (status.toUpperCase() === "REVOKE") stats.revoke++;
			}
			return { ...state, access_tokens: payload, token_stats: stats };
		},
		changeNotifyCount(
			state = {
				currentUser: {},
			},
			action,
		) {
			return {
				...state,
				currentUser: {
					...state.currentUser,
					notifyCount: action.payload.totalCount,
					unreadCount: action.payload.unreadCount,
				},
			};
		},
		updateTwoFASetup(state, { payload }) {
			if (!payload) return { ...state, twofa_setup: null };
			const { token, name, mfa_challenge } = payload;
			return { ...state, twofa_setup: { token, name, mfa_challenge } };
		},
		updateLoginChallenge(state, { payload }) {
			if (!payload) return { ...state, challenge_details: null };
			return { ...state, challenge_details: payload };
		},
	},
	effects: {
		*login({ payload }, { call, put }) {
			const response = yield call(userLogin, payload);
			yield put({
				type: "changeLoginStatus",
				payload: response,
			});

			if (response.status === "OK!") {
				if (payload.type === "sso" && response.challenge) {
					localStorage.setItem("challenge_session", response.challenge.session);
					localStorage.setItem("challenge_name", response.challenge.challenge_name);
					localStorage.setItem("challenge_username", response.challenge.username);
					localStorage.setItem("challenge_info", JSON.stringify(response));
					history.replace("/user/challenge");
					return;
				}

				// Fetch User Information and store the JWT Token + User Information into local storage
				// User Information consists of all the policies for the user
				//
				// Note: JWT Token must be saved first! Request will intercept the request and retrieve JWT Tokne from localstorage
				setJWTToken(response);
				const userInformation = yield call(getMe);
				localStorage.setItem("user-information", JSON.stringify(userInformation));

				// Redirect the user to the redirect URL.
				const urlParams = new URL(window.location.href);
				const params = getPageQuery();
				let { redirect } = params;

				if (redirect) {
					try {
						const redirectUrlParams = new URL(redirect);

						if (redirectUrlParams.origin === urlParams.origin) {
							redirect = redirect.substr(urlParams.origin.length);

							if (redirect.match(/^\/.*#/)) {
								redirect = redirect.substr(redirect.indexOf("#") + 1);
							}
						} else {
							window.location.href = "/";
							return;
						}
					} catch (err) {
						window.location.href = "/";
						return;
					}
				}
				history.replace(redirect || "/");
				window.location.reload(true);
			} else {
				// status is not OK
				logger("Invalid login!", 0);
				showServerResponse(response);
				return response;
			}
		},
		*logout(_, { call }) {
			yield call(userLogout);

			clearStorageAndRedirectToLogin();
		},
		*getMe(_, { call, put }) {
			let jwtFromStorage = getJWTToken();

			// [DEPRECIATED] ~~~If Datastore exists, try to get the user ID.~~~
			// Now the app can support both httpOnly cookie and JWT
			// Just fetch /me only :)
			const response = yield call(getMe);
			if (response.serverResponse) {
				// request.js will have handled any token problems.
				// return now to avoid the put call to updateCurrentUserState, so that the state info don't get messed up.
				return;
			}

			// response.avatar = '/avatar.png';
			response.avatar = config.publicResourceBaseURL + config.defaultAvatar;
			yield put({
				type: "updateCurrentUserState",
				payload: response,
			});
		},
		*getAccessTokens(_, { call, put }) {
			let response = yield call(svc_fetchAll, "sessions");

			if (showServerResponse(response)) {
				response = response.map((token) => {
					token.expiry_date = moment(token.expiry_date).format("YYYY-MM-DD HH:mm:ss");
					return token;
				});
				yield put({
					type: "updateAccessTokens",
					payload: response,
				});
			}
		},
		*createToken({ payload }, { call, put }) {
			const response = yield call(svc_addOne, "sessions", payload);
			showServerResponse(response);
			return response;
		},
		*changePassword({ payload }, { call }) {
			let results = yield call(changePassword, payload);
			if (results.status == "OK") {
				return true;
			} else {
				return false;
			}
		},
		*submitLoginChallenge({ payload }, { call, put }) {
			payload.session = localStorage.getItem("challenge_session");
			payload.challenge = localStorage.getItem("challenge_name");
			payload.username = localStorage.getItem("challenge_username");

			const response = yield call(svc_addOne, "users/challenge", payload);
			showServerResponse(response);

			if (response.message === "Invalid session for the user, session is expired.") {
				clearStorageAndRedirectToLogin();
				return response;
			}

			if (payload.challenge === "SOFTWARE_TOKEN_MFA") {
				if (response.status === "OK!") {
					if (payload.type === "sso" && response.challenge) {
						localStorage.setItem("challenge_session", response.challenge.session);
						localStorage.setItem("challenge_name", response.challenge.challenge_name);
						localStorage.setItem("challenge_username", response.challenge.username);
						localStorage.setItem("challenge_info", JSON.stringify(response));
						history.replace("/user/challenge");
						return;
					}

					// Fetch User Information and store the JWT Token + User Information into local storage
					// User Information consists of all the policies for the user
					//
					// Note: JWT Token must be saved first! Request will intercept the request and retrieve JWT Tokne from localstorage
					setJWTToken(response);
					const userInformation = yield call(getMe);
					localStorage.setItem("user-information", JSON.stringify(userInformation));

					// Redirect the user to the redirect URL.
					const urlParams = new URL(window.location.href);
					const params = getPageQuery();
					let { redirect } = params;

					if (redirect) {
						try {
							const redirectUrlParams = new URL(redirect);

							if (redirectUrlParams.origin === urlParams.origin) {
								redirect = redirect.substr(urlParams.origin.length);

								if (redirect.match(/^\/.*#/)) {
									redirect = redirect.substr(redirect.indexOf("#") + 1);
								}
							} else {
								window.location.href = "/";
								return;
							}
						} catch (err) {
							window.location.href = "/";
							return;
						}
					}
					history.replace(redirect || "/");
					window.location.reload(true);
				} else {
					// status is not OK
					logger("Invalid login!", 0);
					showServerResponse(response);
					return response;
				}
			} else {
				if (response.status === "OK") {
					if (Object.prototype.hasOwnProperty.call(response, "setup_twofa")) {
						// Need to setup 2FA
						localStorage.clear();
						localStorage.setItem("twofa-setup", JSON.stringify(response));
						setJWTToken(response);
						history.push({ pathname: "/user/twofa-setup" });
						yield put({
							type: "updateTwoFASetup",
							payload: response,
						});
						return response;
					} else {
						clearStorageAndRedirectToLogin();
						return response;
					}
				}
			}

			// if (
			// 	response.status === "OK" ||
			// 	response.message === "Invalid session for the user, session is expired."
			// ) {
			// 	localStorage.clear();
			// 	history.replace("/user/login");
			// }
			return response;
		},
		*parseTwoFASetupToken({}, { put }) {
			let storedInfo = localStorage.getItem("twofa-setup");
			if (storedInfo) {
				try {
					let info = JSON.parse(storedInfo);
					yield put({
						type: "updateTwoFASetup",
						payload: info,
					});
				} catch (err) {}
			}
		},
		*getLoginChallenge({}, { put }) {
			let storedInfo = localStorage.getItem("challenge_info");
			if (storedInfo) {
				try {
					let info = JSON.parse(storedInfo);
					console.log("Info:", info);
					yield put({
						type: "updateLoginChallenge",
						payload: info,
					});
				} catch (err) {}
			}
		},
		*setupTwoFA({ payload }, { call, put }) {
			const { device_name, challenge_code } = payload;
			const response = yield call(svc_addOne, "users/setup_twofa", {
				device_name,
				mfa_challenge_response: challenge_code,
			});

			if (response.status === "OK") {
				showServerResponse(response);
				yield put({ type: "updateTwoFASetup", payload: null });
				clearStorageAndRedirectToLogin();
			}
			return response;
		},
	},
};
export default UserModel;
