/**
 * request - web request extended interface
 * Detailed api documentation: https://github.com/umijs/umi-request
 */
import { extend } from "umi-request";
import { notification } from "antd";
import settings from "../../config/config.json";
import { redirectUserToLoginPage } from "@/utils/redirect";
import logger from "@/utils/logger";
import { getJWTToken } from "@/utils/jwt";

const codeMessage = {
	200: "OK.",
	201: "Created",
	202: "Accepted",
	204: "No content",
	400: "Bad request",
	401: "Unauthorized",
	403: "Forbidden",
	404: "Not Found.",
	406: "Not acceptable",
	410: "Gone!",
	422: "Cannot process entity",
	500: "Server error: Something went wrong!",
	502: "Bad gateway. (Try again later)",
	503: "Service Unavailable",
	504: "Network Timeout",
};

// Error handler routines to show notification messages in UI
export const errorHandler = (error) => {
	let { response } = error;
	if (response && response.status) {
		const errorText = codeMessage[response.status] || response.statusText;
		const { status, url, body } = response;

		if (response.status == 204) {
			notification.info({
				message: "No Data Found",
				description: "Failed to find any related data for this request.",
			});
		} else if (response.status == 400) {
			// Instead of returning a hardcoded message,
			// Return the error message from server.
			// In situations where Body is missing data/invalid data format,
			// the API will reply messages like "Please enter a correct phone number with format like +65 XXXX XXXX"
			// Currently we will do this for only 400 status (Bad Request)
			//
			// Server Reply
			// {
			//   status: "ERROR",
			//   error: "Please enter a correct phone number with format like +65 XXXX XXXX"
			// }
			response.serverResponse = error.data;
			let message = null;
			console.log(response.serverResponse);
			if (Object.prototype.hasOwnProperty.call(response.serverResponse, "message"))
				message = response.serverResponse.message;
			else message = error.data.error;
			notification.error({
				message: "Error",
				description: message,
			});
		} else {
			response.serverResponse = error.data;
			notification.error({
				message: `Error: ${status}: ${url}`,
				description: errorText,
			});
		}
		// Server is alive and is explicitly asking us to invalidate the token.
		if (response.serverResponse && response.serverResponse.invalidateToken) {
			logger("@utils/request.js - Detected JWT error. Invalidating token.", 0);
			localStorage.clear();
			redirectUserToLoginPage();
			return response;
		}
	} else if (!response) {
		notification.error({
			description: "Please check your Internet connection.",
			message: "Connection Error!",
		});
	}

	return response;
};

// function to reload Auth token from browser local store.
export const reloadToken = () => {
	let dataFromStorage = getJWTToken();

	if (dataFromStorage && dataFromStorage != "null") {
		return { Authorization: `Bearer ${dataFromStorage}` };
	} else {
		return null;
	}
};

const request = extend({ errorHandler });

request.interceptors.request.use((url, options) => {
	let jwtToken = reloadToken();
	if (jwtToken) {
		options.headers["Authorization"] = jwtToken["Authorization"];

		return {
			url,
			options: { ...options },
		};
	} else {
		return { url, options };
	}
});

export default request;
