import request from "@/utils/request";
import logger from "@/utils/logger";
const config = require("../../config/config.json");

// ******************************
// * Version 1 - Jackson Tan @ 2020-09-02
// * This file should not require modifications, unless necessary.
// * The endpoint calls have been aligned to the api routes.
// * The functions in this file provides a standard REST API call against the API.
// * This saves you the need to create individual service files if you only require standard CRUD functions.
// ******************************

export async function svc_fetchAll(endpointBasePrefix) {
	logger(`@svc/${endpointBasePrefix} StandardSvc - GET ${config.apiUrl}${endpointBasePrefix}`, 5);
	return request(`${config.apiUrl}${endpointBasePrefix}`);
}

export async function svc_fetchOne(endpointBasePrefix, identifier) {
	logger(
		`@svc/${endpointBasePrefix} StandardSvc - GET ${config.apiUrl}${endpointBasePrefix}/${identifier}`,
		5,
	);
	return request(`${config.apiUrl}${endpointBasePrefix}/${identifier}`);
}

export async function svc_addOne(endpointBasePrefix, formData, messageLabel = null) {
	logger(formData, 5);
	logger(messageLabel, 5);
	let finalMessageLabel = messageLabel == null ? "" : `/${messageLabel}`;
	logger(
		`@svc/${endpointBasePrefix} StandardSvc - POST ${config.apiUrl}${endpointBasePrefix}${finalMessageLabel}`,
		5,
	);
	return request(`${config.apiUrl}${endpointBasePrefix}${finalMessageLabel}`, {
		method: "POST",
		data: formData,
	});
}

export async function svc_editOne(endpointBasePrefix, formData, idxFieldName, messageLabel = null) {
	let finalMessageLabel = messageLabel == null ? "" : `/${messageLabel}`;
	logger(
		`@svc/${endpointBasePrefix} StandardSvc - PUT ${config.apiUrl}${endpointBasePrefix}/${formData[idxFieldName]}${finalMessageLabel}`,
		5,
	);
	return request(
		`${config.apiUrl}${endpointBasePrefix}/${formData[idxFieldName]}${finalMessageLabel}`,
		{
			method: "PUT",
			data: formData,
		},
	);
}

export async function svc_deleteOne(
	endpointBasePrefix,
	formData,
	idxFieldName,
	messageLabel = null,
) {
	let finalMessageLabel = messageLabel == null ? "" : `/${messageLabel}`;
	logger(
		`@svc/${endpointBasePrefix} StandardSvc - DELETE ${config.apiUrl}${endpointBasePrefix}/${formData[idxFieldName]}${finalMessageLabel}`,
		5,
	);
	return request(
		`${config.apiUrl}${endpointBasePrefix}/${formData[idxFieldName]}${finalMessageLabel}`,
		{
			method: "DELETE",
			data: formData,
		},
	);
}

export async function svc_search(endpointBasePrefix, searchParams) {
	logger(
		`@svc/${endpointBasePrefix} StandardSvc -GET (+SEARCH) ${config.apiUrl}${endpointBasePrefix}`,
		5,
	);
	return request(`${config.apiUrl}${endpointBasePrefix}`, {
		method: "GET",
		params: searchParams,
	});
}
