import request from "@/utils/request";
import logger from "@/utils/logger";
const config = require("@/../config/config.json");
const endpointBasePrefix = "users";

// ******************************
// * This file should not require modifications, unless necessary.
// * The endpoint calls have been aligned to the api routes.
// ******************************

export async function userLogin(params) {
	return request(`${config.apiUrl}${endpointBasePrefix}/login`, {
		method: "POST",
		data: params,
	});
}

export async function userLogout() {
	return request(`${config.apiUrl}${endpointBasePrefix}/logout`, {
		method: "POST",
	});
}

export async function getMe() {
	logger(`@services/user.js - getMe`, 5);
	return request(`${config.apiUrl}me`);
}

export async function changePassword({ current_password, new_password }) {
	logger(`@services/user.js - getCurrentUser`, 5);
	return request(`${config.apiUrl}me/password`, {
		method: "PUT",
		data: {
			password: current_password,
			new_password: new_password,
		},
	});
}

//This is supposed to get notifications
//Not implemented, the API needs to be created to return an array of notifications.
export async function getUserNotifications() {
	logger(`@services/session.js - getUserNotifications (NOT IMPLEMENTED!)`, 1);
	// return request(`${config.apiUrl}notices`);
	return [
		{
			id: "1",
			message: "There were 65 new IP addresses queried since you last logged in.",
			read: false,
			timestamp: "2020-08-02",
		},
		{
			id: "2",
			message: "There were 3 SMSs sent since you last logged in.",
			read: false,
			timestamp: "2020-08-03",
		},
	];
}
