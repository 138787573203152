import "lodash";
import { svc_search, svc_addOne, svc_editOne, svc_deleteOne } from "@/services/";
import { svc_downloadFile } from "@/services/files";
import logger from "@/utils/logger";
import { convertISODateTimeToString } from "@/utils/convertTime";
import { showServerResponse } from "@/utils/message";
import moment from "moment";
const endpointBasePrefix = "registrants";

const Model = {
	namespace: "m_Registrants",
	state: {
		registrants: [],
	},
	reducers: {
		updateModelState(state, { payload: { response } }) {
			return { ...state, registrants: response };
		},
	},
	effects: {
		*fetchAll({ payload }, { call, put }) {
			const response = yield call(svc_search, endpointBasePrefix, payload);
			if (!showServerResponse(response, true)) return;

			logger("** @effects - fetchAll: " + JSON.stringify(response), 4);
			_.forEach(response.result, function (row) {
				row.key = row.id;
			});

			yield put({
				type: "updateModelState",
				payload: { response },
			});
		},
		*addOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			const response = yield call(svc_addOne, endpointBasePrefix, payload, payload.id);
			return showServerResponse(response);
		},
		*editOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			const response = yield call(svc_editOne, endpointBasePrefix, payload, "id", payload.id);
			return showServerResponse(response);
		},
		*deleteOne({ payload }, { call, put }) {
			payload = _.omit(payload, ["key"]);
			const response = yield call(
				svc_deleteOne,
				endpointBasePrefix,
				payload,
				"id",
				payload.ip,
			);
			return showServerResponse(response);
		},
		*downloadCSV({ payload }, { call, put }) {
			const fileName = `Registrants_${moment().format("DD-MM-YYYY@HHmmss")}.csv`;
			const response = yield call(svc_downloadFile, `${endpointBasePrefix}_download`, payload);
			console.log(response)
			if(response.type === "text/plain"){
				const file = new Blob(
					[response],
					{
						// type: "application/octet-stream",
						type: "application/csv",
					});
				const fileURL = URL.createObjectURL(file);
				var a = document.createElement("A");
				a.href = fileURL;
				a.target = "file";
				a.setAttribute("download", fileName);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			}
			else
				showServerResponse(response);
			return response;
		}
	},
};

export default Model;
